@import '@/styles/variables.scss';


.headerContent {
  background: $reddish-orange;
  height: 226px;
  :global {
    .ant-anchor-ink {
      &::before {
        display: none;
      }
    }
  }
}
.headnerSection {
  position: fixed;
  position: sticky;
  left: 0;
  top: 0;
  z-index: $zIndex-3;
  background-color: $reddish-orange;
  .searchSection {
    max-width: $max-inner-width;
    margin: auto;
  }
}
.inputContent,
.homeSeachContent {
  overflow: hidden;
}
.headerBar {
  position: relative;
  flex: 0 0 $main-nav-height;
  z-index: 10;
  padding: 0;
}

// .contentWrapper {
//   position: relative;
//   flex: 1;
//   height: 100%;
//   // border-top: 40px solid $reddish-orange;
//   // transition: all 1s;
// }

.bodyContent {
  position: relative;
  background: #fff;
  flex: 1;
  // padding: $padding-base 0;
  // margin: auto;
  &.frap {
    max-width: $max-inner-width;
    margin: auto;
  }
}
.showAdvertiseClose {
  height: 0;
}
.advertiseSection {
  transition: all 0.5s;
  overflow: hidden;
}
